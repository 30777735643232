
.paginations_sizes{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-top: auto;
	
}
.paginations_size{
	cursor: pointer;
    border: 1px solid #130F26;
	padding: 5px;
	margin: 5px;
	width: 40px;
	height: 40px;
	justify-content: center;
}
.paginations_size.selected{
	background-color:#0C4B57;	
}
.paginations_size.selected button{
	color: #fff;
}
.paginations_size button{
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	font-size: 14px;
	font-family: 'Jura';
}
.pagnations_next button{
	background-color: rgba(0, 0, 0, 0);
	border: none;
}

