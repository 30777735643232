.user_agreement{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
.user_agreement li{
    list-style-type: disc;
    margin-left: 5%;
    font-family: 'Jura', sans-serif;
    color: #130F26;
}

@media screen  and ((max-width: 767px)){
    .user_agreement{
        margin-left: 0;
        margin-right: 0;
        width: 95%;
        margin: 0 auto;
        padding-bottom: 20px;
    }
}