.filter_mobile_current {
    z-index: 10001;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: auto;
    transition: width 0.5s ease;
    visibility: hidden;
    opacity: 0;
}

.filter_mobile_current.active {
    visibility: visible;
    opacity: 1;
    width: 100vw;
}

.filter_mobile_content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 70%;
    background-color: white;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filter_mobile_current.active .filter_mobile_content {
    transform: translateX(0);
}
.filter_mobile_content_header{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
}
.filter_mobile_content_header button{
    margin-left: auto;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    
}

.filter_mobile_content_button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    border-bottom: 1px solid rgb(156, 152, 152);
    margin-bottom: 15px;
}
.filter_mobile_content_button button{
    display: flex;
    font-size: 15px;
    width: 100%;
    height: 20px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
}
.filter_mobile_content_button img{
    margin-left: auto;
}

.filter_mobile_content_price{
    width: 80%;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.filter_mobile_content_price.active{
    height: auto;
    margin-bottom: 20px;
}

.mobile_filter_content_price_custom_tooltip {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
  }

.mobile_filter_content_genders{
    width: 80%;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile_filter_content_genders.active{
    height: auto;
    margin-bottom: 20px;
}
.mobile_filter_content_gender{
    display: flex;
    flex-direction: row;
    margin-bottom: 7px;
}

.mobile_filter_content_gender p{
    font-size: 16px;
}

.mobile_filter_content_gender input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}



.mobile_filter_content_categorys{
    width: 80%;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile_filter_content_categorys.active{
    height: auto;
    margin-bottom: 20px;
}
.mobile_filter_content_category{
    display: flex;
    margin-bottom: 7px;
}

.mobile_filter_content_category p{
    font-size: 16px;
}

.mobile_filter_content_category input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}



.mobile_filter_content_colors{
    width: 80%;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile_filter_content_colors.active{
    height: auto;
    margin-bottom: 20px;
}
.mobile_filter_content_color{
    display: flex;
    margin-bottom: 7px;
}
.mobile_filter_content_color p{
    font-size: 16px;
}

.mobile_filter_content_color input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}

.mobile_filter_content_colors_list{
    overflow-y: auto;
}


.mobile_filter_content_brands{
    width: 80%;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile_filter_content_brands.active{
    height: auto;
    margin-bottom: 20px;
}
.mobile_filter_content_brand{
    display: flex;
    margin-bottom: 7px;
}
.mobile_filter_content_brand p{
    font-size: 16px;
}
.mobile_filter_content_brand input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}

.mobile_filter_content_brands_list{
    overflow-y: auto;
}



.mobile_filter_content_models{
    width: 80%;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile_filter_content_models.active{
    height: auto;
    margin-bottom: 20px;
}
.mobile_filter_content_model{
    display: flex;
    margin-bottom: 7px;
}
.mobile_filter_content_model p{
    font-size: 16px;
}

.mobile_filter_content_model input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}

.mobile_filter_content_models_list{
    overflow-y: auto;
}



.mobile_filter_content_sizes{
    width: 80%;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile_filter_content_sizes.active{
    height: auto;
    margin-bottom: 20px;
}
.mobile_filter_content_size{
    display: flex;
    margin-bottom: 7px;
}
.mobile_filter_content_size p{
    font-size: 16px;
}

.mobile_filter_content_size input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}

.mobile_filter_content_sizes_list{
    overflow-y: auto;
}







































.mobile_filter_content_confirm{
    margin-bottom: 10px;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: center;
}
.mobile_filter_content_confirm button{
    width: 50%;
    height: 30px;
    margin-right: 4px;
    border: none;
    background-color: #0C4B57;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.mobile_filter_content_confirm p{
    margin-left: 5px;
    color: white;
    background-color: red;
    border-radius: 100%;
    padding-right: 4px;
    padding-left: 2px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}