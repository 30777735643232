.mobile_header{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 90px;
}
.mobile_header button{
    border: none;
    background-color: rgba(0, 0, 0, 0);
}
.mobile_header_lines{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
}
.mobile_header_lines_button{
    display: flex;
    flex-direction: column;
}
.mobile_header_lines_button img{
    margin-bottom: 4px;
}

.mobile_header_logo{
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: center;
}
.mobile_header_logo a{
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile_header_logo img{
    position: relative;
    width: 70%;
}

.mobile_header_auth{
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: center;
}
.cart_count1 {
    position: absolute;
    background-color: #ff0000; /* Цвет фона кружка */
    color: #fff; /* Цвет текста в кружке */
    border-radius: 50%; /* Делаем круглый кружок */
    width: 15px; /* Ширина кружка */
    height: 15px; /* Высота кружка */
    font-size: 12px; /* Размер шрифта текста в кружке */
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0%, 120%); /* Центрируем кружок относительно иконки корзины */
  }