.profile_popup {
    right: -100%; /* Начальное положение за пределами экрана */
	display: flex;
	position: absolute;
	width: 200px;
	height: 80px;
	display: flex;
	z-index: 10;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0,0.0);
    transition: right 0.5s ease;

}
.profile_popup.active {
    right: 0; /* Положение при активации */
}

.auth button{
    cursor: pointer;
}

.profile_button{
    border: none;
    background-color: #0C4B57;
    width: 90%;
    height: 30px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.profile_button button{
    cursor: pointer;
    align-items: center;
    display: flex;
    font-size: 20px;
    width: 100%;
    border: none;
    background-color: rgba(0,0,0,0);
    justify-content: center;
}
.profile_button p{
    color: white;
}