.item {
    width: 210px;
    display: flex;
    flex-direction: row;
    min-height: 270px;
    max-height: 270px;
}

.item_prev{
    height: 170px;
}
.item_prev img{
    width: 210px;
}
.price{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 2px;
    padding-left: 2px;
    border-bottom: #0C4B57 1px solid;
    border-top: #0C4B57 1px solid;
}
.actual_price p{
    display: flex;
    color: #0C4B57;
    font-size: 14px;
    font-weight: 800;
}

.oldprice p{
    margin-left: 5px;
    color: red;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: line-through;
}
.description {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 5px;
    /* height: 40%; */
}

.nameprod {

    color: var(--, #130F26);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    
}

.gender_item{
    /* color: #808080; */
    color: #0C4B57;
    font-family: Jura;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.color {
    color: #130F26;
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

}



@media only screen and (min-width: 320px) and (max-width: 768px) {
    .item {
        width: 150px;
        min-height:290px;
        max-height:290px;
    }
    .item_prev{
        height: 130px;
    }
    .item_prev img{
        width: 150px;
        z-index: 0;
        position: relative;
    }
}