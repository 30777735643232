
.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 1%;
  width: 100%;
  height:80%;
  margin: 0 auto;
}

.center {
  margin: 0 auto;
  width: 70%; /*Установите ширину в 1440px (соответствующую ширине изображений) */
  height: 80%;
}

.slide {
  width: 100%; /* 100% ширины внутри .center для соответствия размерам изображений */
  height: 70%; /* 100% высоты внутри .center для соответствия размерам изображений */
}

.slick-slider{
  
}

.slide img{
  height: 100%;
  width: 100%;
}

@media screen  and ((max-width: 767px)) {
  .center {
    width: 100%; /* 100% ширины внутри .center для соответствия размерам изображений */
    height: 70%; /* 100% высоты внутри .center для соответствия размерам изображений */
  }
}


/* Дополнительные стили для вашего слайдера */
/* custom-slider.css */
/* Стили для левой стрелки */