.desktop_filter_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.desktop_filter_content_header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}



.desktop_filter_content_price{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.desktop_filter_content_price_custom_tooltip {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
}
.desktop_filter_content_price_sale_picker{
    margin-top: 10px;
    display: flex;
}
.desktop_filter_content_price_sale_picker p{
    font-size: 15px;
}
.desktop_filter_content_price_sale_picker input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}



.desktop_filter_content_genders{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.desktop_filter_content_genders h3{
    margin-bottom: 5px;
}
.desktop_filter_content_gender{
    display: flex;
    flex-direction: row;
}
.desktop_filter_content_gender p{
    font-size: 16px;
}
.desktop_filter_content_gender input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    background-color: gray;
}



.desktop_filter_content_categorys{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.desktop_filter_content_categorys h3{
    margin-bottom: 5px;
}
.desktop_filter_content_categorys_list{
    height: 100px;
    overflow-y: auto;
}
.desktop_filter_content_categorys_list::-webkit-scrollbar {
    width: 2px; /* ширина полосы прокрутки в WebKit браузерах */
}
.desktop_filter_content_categorys_list::-webkit-scrollbar-track {
    background: transparent; /* цвет фона дорожки */
}
.desktop_filter_content_categorys_list::-webkit-scrollbar-thumb {
    background-color: gray; /* цвет полосы прокрутки */
    border-radius: 0px; /* радиус скругления углов */
}
.desktop_filter_content_category{
    display: flex;
}
.desktop_filter_content_category p{
    font-size: 16px;
}
.desktop_filter_content_category input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
}



.desktop_filter_content_colors{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.desktop_filter_content_colors h3{
    margin-bottom: 5px;
}
.desktop_filter_content_colors_list{
    height: 100px;
    overflow-y: auto;
}
.desktop_filter_content_color{
    display: flex;
}
.desktop_filter_content_color p{
    font-size: 16px;
}
.desktop_filter_content_color input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
}
.desktop_filter_content_colors_list::-webkit-scrollbar {
    width: 2px; /* ширина полосы прокрутки в WebKit браузерах */
}
.desktop_filter_content_colors_list::-webkit-scrollbar-track {
    background: transparent; /* цвет фона дорожки */
}
.desktop_filter_content_colors_list::-webkit-scrollbar-thumb {
    background-color: gray; /* цвет полосы прокрутки */
    border-radius: 0px; /* радиус скругления углов */
}



.desktop_filter_content_brands{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.desktop_filter_content_brands h3{
    margin-bottom: 5px;
}
.desktop_filter_content_brands_list{
    height: 100px;
    overflow-y: auto;
}
.desktop_filter_content_brand{
    display: flex;
}
.desktop_filter_content_brand p{
    font-size: 16px;
}
.desktop_filter_content_brand input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
}
.desktop_filter_content_brands_list::-webkit-scrollbar {
    width: 2px; /* ширина полосы прокрутки в WebKit браузерах */
}
.desktop_filter_content_brands_list::-webkit-scrollbar-track {
    background: transparent; /* цвет фона дорожки */
}
.desktop_filter_content_brands_list::-webkit-scrollbar-thumb {
    background-color: gray; /* цвет полосы прокрутки */
    border-radius: 0px; /* радиус скругления углов */
}



.desktop_filter_content_models{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.desktop_filter_content_models h3{
    margin-bottom: 5px;
}
.desktop_filter_content_models_list{
    height: 100px;
    overflow-y: auto;
}
.desktop_filter_content_model{
    display: flex;
}
.desktop_filter_content_model p{
    font-size: 16px;
}
.desktop_filter_content_model input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
}

.desktop_filter_content_models_list::-webkit-scrollbar {
    width: 2px; /* ширина полосы прокрутки в WebKit браузерах */
}

.desktop_filter_content_models_list::-webkit-scrollbar-track {
    background: transparent; /* цвет фона дорожки */
}

.desktop_filter_content_models_list::-webkit-scrollbar-thumb {
    background-color: gray; /* цвет полосы прокрутки */
    border-radius: 0px; /* радиус скругления углов */
}




.desktop_filter_content_sizes{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.desktop_filter_content_sizes h3{
    margin-bottom: 5px;
}
.desktop_filter_content_sizes_list{
    height: 100px;
    overflow-y: auto;
}
.desktop_filter_content_size{
    display: flex;
}
.desktop_filter_content_size p{
    font-size: 16px;
}
.desktop_filter_content_size input[type='checkbox']{
    margin-right: 5px;
    margin-bottom: 5px;
}

.desktop_filter_content_sizes_list::-webkit-scrollbar {
    width: 2px; /* ширина полосы прокрутки в WebKit браузерах */
}

.desktop_filter_content_sizes_list::-webkit-scrollbar-track {
    background: transparent; /* цвет фона дорожки */
}

.desktop_filter_content_sizes_list::-webkit-scrollbar-thumb {
    background-color: gray; /* цвет полосы прокрутки */
    border-radius: 0px; /* радиус скругления углов */
}

.desktop_filter_content_confirm{
    margin-bottom: 10px;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: center;
}
.desktop_filter_content_confirm button{
    width: 50%;
    height: 30px;
    margin-right: 4px;
    border: none;
    background-color: #0C4B57;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.desktop_filter_content_confirm p{
    margin-left: 5px;
    color: white;
    background-color: red;
    border-radius: 100%;
    padding-right: 4px;
    padding-left: 2px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}