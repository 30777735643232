.cart_popup {
    display: flex;
    position: absolute;
    width: 385px;
    height: 0; /* Начальная высота 0 */
    background-color: white;
    justify-content: center;
    z-index: 10;
    left: 64%;
    height: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: height 0.5s ease; /* Добавление плавности */
    overflow: hidden;
    flex-direction: column;
}

.cart_popup.active {
    height: 398px; /* Высота при активации */
    transform: translateY(0); /* Показать элемент */
}

.cart_popup_header{
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 35px;
    width: 325px;
    height: 40px;
}
.cart_popup_header_left{
    flex-direction: column;
}
.cart_popup_header_right{
    margin-left: auto;
    margin-top: auto;
}
.cart_popup_header_right button{
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    border: none;
}
.cart_popup_header_Korzina{
    display: flex;
    color: #130F26;
    font-size: 18px;
    font-family: Jura;
    font-weight: 700;
    word-wrap: break-word
}
.cart_popup_header_count{
    display: flex;
    color: #808080;
    font-size: 16px;
    font-family: Jura;
    font-weight: 500;
    word-wrap: break-word
}
.cart_popup_items{
    margin-left: 25px;
    margin-right: 25px;
    height: 250px;
    list-style: none;
    padding: 0;
    max-height: 250px;
    overflow-y: auto;
    
}
.cart_popup_item_right_discount{
    color: red;
    text-align: right;
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.cart_popup_item_right_crossed{
    color: var(--unnamed, #808080);
    text-align: right;
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line:  line-through; 
    white-space: nowrap;
}
.cart_popup_item_right_default{
    color: black;
    text-align: right;
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
}
.cart_popup_item {
    
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
    /* padding: 10px; */
    
  }
.cart_popup_item_firstline{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
}
.cart_popup_item_left{
    width: 70px;
    
}
.cart_popup_item_left img{
    width: 70px;
}
.cart_popup_item_center{
    display: flex;
    flex-direction: column;
    width: 187px;


}
.cart_popup_item_center_name{
    color: #130F26;
    font-size: 18px;
    font-family: Jura;
    font-weight: 600;
    word-wrap: break-word;
}
.cart_popup_item_subline{
    /* height: 18px; */
    margin-bottom: 10px;
}
.cart_popup_item_center_out_of_2{
    color: red;
    margin-top: 20px;
}
.cart_popup_item_center_gender{
    color: #808080;
    font-size: 16px;
    font-family: Jura;
    font-weight: 500;
    word-wrap: break-word
}
.cart_popup_item_right{
    /* width: 75px; */
}

.cart_popup_item_secondline{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cart_popup_item_secondline button{
    background: #F2F2F2; 
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: none;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
}
.cart_popup_item_secondline_minus img{
    margin-bottom: 5px;
}
.cart_popup_item_secondline_plus img{
    margin-top: 5px;
}
.cart_popup_item_secondline p{
    color: #130F26;
    font-size: 16px;
    font-family: Jura;
    font-weight: 500;
    word-wrap: break-word
}
.cart_popup_item_secondline p1{
    color: #808080;
    font-size: 16px;
    font-family: Jura;
    font-weight: 500;
    word-wrap: break-word
}
.cart_popup_confirm{
    margin-bottom: 35px;
    margin-left: 25px;
    margin-right: 25px;
}
.cart_popup_confirm button{
    width: 100%;
    height: 70px;
    background-color: #0C4B57;
    position: sticky;
    bottom: 0;
    border: none;
    color: #F9F9F9;
    font-size: 18px;
    font-family: Jura;
    font-weight: 400;
    word-wrap: break-word;
}
.cart_popup_empty{
    display: flex;
    margin-top: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cart_popup_empty button{
    width: 320px;
    height: 49px;
    color: #130F26;
    font-size: 18px;
    font-family: Jura;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 30%;
    margin-top: 30%;
    border: none;
}
.cart_popup_empty.active button{
    width: 320px;
    height: 49px;
    color: #130F26;
    font-size: 18px;
    font-family: Jura;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 30%;
    margin-top: 30%;
    border: none;
}
.cart_popup_empty p{
    color: #808080;
    font-size: 18px;
    font-family: Jura;
    font-weight: 500;
    word-wrap: break-word
}

@media screen and (min-width: 768px) and ((max-width: 1023px)) {
    .cart_popup {
        display: flex;
        position: absolute;
        width: 385px;
        height: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        z-index: 10;
        left: 40%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transform: translateY(0);
        flex-direction: column;
        transition: height 0.5s ease; /* Добавление плавности */
        overflow: hidden;
    }
    .cart_popup.active {
        height: 398px; /* Высота при активации */
        transform: translateY(0); /* Показать элемент */
        
    }   
}
@media screen  and ((max-width: 767px)) {
    .cart_popup {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 0; /* Начальная высота 0 */
        background-color: white;
        display: flex;
        justify-content: center;
        z-index: 10;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: height 0.5s ease, height 0.5s ease; /* Добавление плавности */
        flex-direction: column;
    }
    
    .cart_popup.active {
        height: 397px; /* Высота при активации */
    }
    
    
    .cart_popup_header{
        width: 90%;
    }
    .cart_popup_items{
        width: 90%;
    }
    .cart_popup_item{
        width: 93%;
    }
    .cart_popup_item_firstline{
        width: 100%;
    }
    .cart_popup_item_left img{
        width: 100%;
    }
    .cart_popup_item_left{
        width: 20%;
    }
    .cart_popup_item_center{
        margin-left: 3%;
        width: 44%;
    }
    .cart_popup_item_center_name{
        font-size: 14px;
    }
    .cart_popup_item_center_gender{
        font-size: 12px;
    }
    .cart_popup_item_right{
        padding-left: 15%;
        width: 20%;
    }
    .cart_popup_item_right p{

        font-size: 16px;

    }
}