.CdekMap{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    
}

.ymaps-2-1-79-map-copyrights-promo{
    display: none;
}
.custom_map_choose{
    width: 30%;
    padding-right: 5%;
    padding-left: 5%;
    height: 90%;
}
.custom_map{
    display: flex;
    width: 70%;
    height: 90%;
    margin-right: 2%;
    
}
.custom_map_choose img{
    width: 100%;
}
.custom_map_choose_text_lable {
    font-size: 18px;
    font-weight: bold;
    /* Дополнительные стили */
}
.custom_map_choose_text {
    height: 85%;
}
.custom_map_choose_button button{
    width: 100%;
    height: 30px;
    border: none;
    color: black;
    background-color: #7fff00;
}
.custom_map_choose_button {
    justify-content: center;
    margin-top: auto;
    height: 30px; 

}

@media screen and ((max-width: 767px)) {
    .CdekMap{
        flex-direction: column;
    }
    .custom_map_choose{
        width: 100%;
        height: 30%;
        padding: 0;
        
    }
    .custom_map_choose_button {
        justify-content: center;
        margin-top: auto;
        height: 30px; 
        margin-left: 3%;
        margin-right: 3%;   
    
    }
    .custom_map_choose.active{
        z-index: 9999;
        background-color: #fff;
    }
    .custom_map_choose_text {
        margin-left: 3%;
        height: 60%;
    }
    .custom_map_choose img{
        
        width: 50%;
        margin-left: 3%;
        margin-bottom: 3%;
        margin-top: 3%;
    }
    .custom_map{
        display: flex;
        width: 100%;
        height: 70%;
        
    }       
}
