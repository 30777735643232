
@media screen and (min-width: 769px){
    .mobile_header_1{
        display: none;
    }
}


@media screen and (max-width: 769px){
    .desktop_header{
        display: none;
    }
}


