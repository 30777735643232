.account {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

}

.nav {
    width: 45%;
    display: flex;
    justify-content: flex-start;
}

/*content style*/
.information {
    width: 850px;
}

.avatar {
    position: relative;
    display: inline-block;
    padding-bottom: 50px;
}

.avatar_img {
    padding-top: 100px;
}

.avatar_text {
    position: absolute;
    padding-top: 126px;
    color: var(--unnamed, #808080);
    font-family: Jura;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 18px;
}

.im_fam {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 30px;
}

.name_text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: #000;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 20px;
}

.name_input {
    display: flex;
    justify-content: flex-end;
}

.input_text_name {
    position: relative;
    display: flex;
    width: 300px;
    padding: 20px 25px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #EBEBEB;
    color: var(--unnamed, #130F26);
    text-align: left;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 50px;
}

.img_text_name {
    position: absolute;
    padding-top: 22px;
    padding-right: 20px;
}

.lastname_text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: #000;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 20px;
}

.checkmark {
    width: 18px;
    height: 18px;
}

.info_fio {
    color: var(--unnamed, #808080);
    font-family: Jura;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 497px;
    padding-bottom: 50px;
    margin-top: 10px;
}

.email {
    display: flex;
    justify-content: space-between;
    color: #000;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 270px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.email_input {
    display: flex;
    justify-content: flex-start;
    justify-items: auto;
}

.input_text_email {
    position: relative;
    display: flex;
    width: 650px;
    padding: 20px 25px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #EBEBEB;
    color: var(--unnamed, #130F26);
    text-align: left;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
}

.img_text_email {
    display: flex;
    position: absolute;
    padding-top: 22px;
    padding-right: 20px;
}

.email_item {
    padding-bottom: 15px;
}

.phone_number_account {
    padding-bottom: 20px;
}

.phone_text {
    color: #000;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding-bottom: 20px;
}

.input_text_phone {
    position: relative;
    display: flex;
    width: 650px;
    padding: 20px 25px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--unnamed, #EBEBEB);
    color: var(--unnamed, #130F26);
    text-align: left;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
}

.adress_text {
    color: #000;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 20px;
}

.adress_checkbox {
    color: #000;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.adress_checkbox li {
    padding-bottom: 20px;
}

ul {
    list-style: none;
}


.zakaz {
    color: #000;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.checkbox_img {
    width: 18px;
    height: 18px;
    margin-right: 15px;
}

.content_checkbox {
    display: flex;
}

.table_orders {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.table_order {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 30px;
    border: #808080 1px solid;
    max-width: 850px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.table_order_top{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: #f7f7f7;
    height: 20%;
    padding: 25px 0;
}
.table_order_top_left{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.table_order_top_right{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.table_order_top_right button{
    font-family: 'Jura', sans-serif;
    background-color: #0C4B57;
    color: white;
    border: none;
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
}
.table_order_bottom{
    margin-top: 10px;
    height: 80%;
    display: flex;
    flex-direction: column;

}
.table_order_bottom_left_item{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
}
.table_order_bottom_left{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.table_order_bottom_right{
    padding: 10px 5px;
    background-color: #f7f7f7;
}

.table_order_bottom_right p{
    font-size: 16px;
}

.table_order_bottom_right_span_p{
    font-weight: bold;
    font-size: 14px;
}

.table_order_bottom_left_img{
    width: 25%;
    display: flex;
    justify-content: flex-end;
}
.table_order_bottom_left_img img{
    width: 80%;
}
.table_order_bottom_left_data{
    width:70%;
    display: flex;

    flex-direction: column;
    justify-content: center;
}
.table_order_bottom_left_data_price{
    display: flex;
    align-items: flex-end;
}
.table_order_bottom_left_data_price_actualprice{
    font-size: 16px;
}
.table_order_bottom_left_data_price_actualprice{
    font-size: 16px;
    color: red;
    margin-right: 5px;
}
.table_order_bottom_left_data_price_notactualprice{
    font-size: 13px;
    margin-right: 5px;
}
@media screen and ((max-width: 768px)) {
    .account {
        width: 100%;
    }

    .information {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .im_fam {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .name_input {
        align-items: center;
        justify-content: center;
    }

    .info_fio {
        width: 100%;
        padding-bottom: 10px;
    }

    .email_part{
        padding-bottom: 0px !important; 
    }

    .name {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .lastname {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .lastname_text{
        padding-top: 20px;
    }

    .input_text_name {
        width: 100%;
        margin: 0;
    }

    .email_item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
    }

    .email {
        align-items: center;
    }

    .email_input {
        width: 100%;
    }

    .phone_number_account {
        width: 100%;
        padding-top: 20px;
    }

    .phone_text {
        display: flex;
        align-items: center;
    }

    .table_order_bottom_left_img img{
        width: 100%;
    }

    .table_order_bottom_right p{
        font-size: 14px;
    }
}