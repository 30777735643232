.BrandsPopup{
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    width: 100%;
    z-index: 10;
    background-color: rgb(255, 255, 255);
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
}
.BrandsPopup.active{
    height: 350px;
    transform: translateY(0);
    padding-bottom: 30px;
}
.BrandsPopup_content{
    width: 80%;
    display: flex;
    justify-content: center;
    max-width: 550px;
}
.BrandsPopup_brands{
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}
.BrandsPopup_brands h2{
    margin-bottom: 10px;
}
.BrandsPopup_brands p{
    margin-bottom: 5px;
    font-size: 12px;
}
.BrandsPopup_subbrands{
    display: flex;
    flex-direction: column;
    
}
.BrandsPopup_subbrands h2{
    font-size: 20px;
    margin-bottom: 10px;
}
.BrandsPopup_subbrands_list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: 10px;
}

.BrandsPopup_subbrands_list_item{
    display: flex;
    flex-direction: column;
    width: 120px;
    margin-right: 20px;
}
.BrandsPopup_subbrands p{
    font-size: 15px;
    margin-bottom: 10px;
    width: 120px;
}
