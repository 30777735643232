.modalauth {
  z-index: 9999;
  height: 100vh !important;
  width: 100vw !important;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

.modalauth.authactive {
  opacity: 1;
  pointer-events: all;
}

.modalauth__content {
  padding: 20px;
  background-color: white;
  width: 536px;
  height: 570px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transform: scale(0.5);
  transition: 0.4s all;
}

.modal_auth_flex{
  display: flex;
  
  align-items: center;
  flex-direction: column;
}

.modalauth__content.authactive {
  transform: scale(1);
}

.modalauth__content h2 {
  padding-top: 70px;
  text-align: center;
  /* Выравнивание текста по центру */
  font-family: Jura;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;

}

.modalauth__content p {
  padding-top: 20px;
  font-family: Jura;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.inpuauth {
  width: 90%;
  height: 61px;
  border: 1px solid #ebebeb;
  align-items: center;
  justify-content: center;
  font-family: Jura;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin: 20px;
  padding-left: 25px;
}

.buttonauth {
  width: 95%;
  height: 61px;
  background-color: #0C4B57;
  color: white;
  font-family: Jura;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  
}
.resend_code_button{
  border: none;
  background-color: rgba(0, 0, 0, 0);
  
}
.resend_code_button p{
  color: blue;
}
.resend_code p{
  font-size: 14px;
}
.buttonauthback button{
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
.buttonauthclose button{
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.modalauth__content_top{
  display: flex;
  flex-direction: row;

}
.buttonauthclose{
  margin-left: auto;
}
@media screen and ((max-width: 767px)) {
  .modalauth {
    width: 100%;
    height: 100%;
  }

  .modalauth__content {
    width: 100%;
    height: 100%;
  }
  .inpuauth{
    width: 80%;
  }
  .buttonauth{
    width: 95%;
  }
}