@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap');

a:active, 
a:hover,  
a {
  text-decoration: none;
}

.order{
    display: flex;
    flex-direction: row;
}
.email_part_input h4{
    color: red;
}
.questionnaire{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    width: 50%;
}

.fio_part{
    display: flex;
    flex-direction: column;
}

.text_order{
    margin-left: 10%;
    padding-top: 100px;
    font-family: 'Jura', sans-serif;
    padding-bottom: 50px;

}
.fio_label{
    margin-bottom: 20px;
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.fio1{
    width: 32%;
    padding: 12px 20px;
    align-items: center;
    border: 1px solid #EBEBEB;
    color: var(--unnamed, #000000);
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
}
.email_part{
    display: flex;
    flex-direction: column;
    
}
.delivery_part{
    display: flex;
    flex-direction: column;
}
.delivery_part_buttons{
    display: flex;
    flex-direction: row;
}
.pmail_and_courierost{
    width: 30%;
    margin-right: 22px;
    padding: 12px 20px;
    align-items: center;
    border: 1px solid var(--unnamed, #130F26);
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 18px;
    font-style: normal; 
    font-weight: 500;
    line-height: normal;
}
.pmail_and_courierost_active{
    background-color: #288182;
    color: var(--unnamed, #F9F9F9);
}
.pmail_and_courierost_normal{
    color: var(--unnamed, #130F26);
    background-color: #F9F9F9;
}

.pay_part{
    display: flex;
    flex-direction: column;
}
.pay_part_lable{

}
.pay_part_buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.payment{
    display: flex;
    width: 40%;
    padding: 12px 10px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--unnamed, #130F26);
    margin-right: 18px;
    margin-bottom: 18px;
    color: var(--unnamed, #130F26);
}
.payment_active{
    background-color: #288182;
    color: white;
}
.payment_normal{
    background-color: #F9F9F9;
    color: var(--unnamed, #130F26);
}

.payment img{
    width: 25px;
    height: 20px;
}
.payment2 img{
    width: 25px;
    height: 20px;
}
.payment3 img{
    width: 25px;
    height: 20px;
}
.payment4 img{
    width: 25px;
    height: 20px;
}

.elem_pay{
    
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.order_cart_price_mobile{
    display: none;
}
.button_confirmation1{
   background: var(--unnamed, #F9F9F9); 
   border: 0px;
   display: flex;
   padding: 20px 30px;
   justify-content: center;
   align-items: center;
   color: var(--unnamed, black);
   font-family: Jura;
   font-size: 18px;
   font-style: normal;
   font-weight: bold;
   line-height: normal;
   margin-bottom: 100px;
}
.button_confirmation_enabled1{
   background: var(--unnamed, #0C4B57); 
   border: 0px;
   display: flex;
   width: 100%;
   padding: 20px 30px;
   justify-content: center;
   align-items: center;
   color: var(--unnamed, #F9F9F9);
   font-family: Jura;
   font-size: 18px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   margin-bottom: 100px;
}
.questionnaire_right{
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
}
.order_cart_items{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.order_cart_price{

}
.help_que{
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
}
.help_que2{
    display: flex;
    justify-content: space-between;
}
.img_right{
    width: 75px;
    height: 75px;
    flex-shrink: 0;
}
.first_elem{
    display: flex;
    justify-content: space-between;
}
.info_right_first2{
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.info_right_second{
    color: var(--unnamed, #808080);
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.info_right2{
    padding-left: 8px;
    width: 150px;
}
.info_right_third{
    color: var(--unnamed, #F00);
    text-align: right;
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.info_right_fouth{
   color: var(--unnamed, #808080);
    text-align: right;
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line:  line-through; 
}
.info_right_fouth_2{
    color: var(--unnamed, #808080);
     text-align: right;
     font-family: Jura;
     font-size: 16px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
 }
.size{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 196px;
}
.plus{
    display: flex;
    padding: 8px 9px;
    align-items: center;
    gap: 10px;
    border-radius: 17px;
    background: #EBEBEB;
    border: 0px;
}

.plus_img{
     
}

.minus{
    display: flex;
    padding: 15px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: #EBEBEB;
    border: 0px;
}
.minus_img{
    width: 12px;
    height: 2px;
}
.number{
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text_size{
    color: var(--unnamed, #808080);
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.info2_right_first{
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 147px;
}
.info2_right_second{
    color: var(--unnamed, #808080);
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.info2_right_third{
    color: var(--unnamed, #0C4B57);
    text-align: right;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.img_right2{
    width: 70px;
    height: 70px;
    flex-shrink: 0; 
}
.size2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    width: 196px;
}
.promo{
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 40px; 
    padding-bottom: 20px;
}
.promo_place{
    display: flex;
    flex-direction: row;
}

.promo_input{
    display: flex;
    width: 50%;
    padding: 12px 20px;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #EBEBEB;
    color: var(--unnamed, #808080);
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
}
.promo_btn{
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    background: #EBEBEB;
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0px;
    width: 50%;
}
.sum_place{
    display: flex;
    justify-content: space-between;
}
.itog_place{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}
.sum{
    color: var(--unnamed, #808080);
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 40px;
}
.sum.promocode{
    text-decoration: line-through;
    color:red;
}
.itog{
    color: var(--unnamed, #130F26);
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
}

@media screen and ((max-width: 1161px)) {
    .pay_part_buttons{
        flex-direction: column;
    }
    .payment{
        width: 100%;
    }
    .delivery_part_buttons button{
        padding: 12px 0px;
    }
    .delivery_part_buttons{
        flex-direction: column;
    }
    .pmail_and_courierost{
        width: 100%;
        margin-bottom: 18px;
    }
    .pmail_and_courierost:last-child{
        margin-bottom: 0px;
    }
    .info_fio{
        margin-top: 0;
    }
}

@media screen and ((max-width: 768px)) {
    .text_order{
        padding-top: 0px;
        padding-bottom: 10px;
        margin-left: 0;
        text-align: center;
    }
    .text_order h1{
        font-size: 30px;
    }
    .order{
        flex-direction: column;
        align-items: center;
    }
    .button_confirmation_enabled1{
        width: 100%;
    }
    .button_confirmation1{
        width: 100%;
    }
    .inputs{
        display: flex;
        flex-direction: column;
        
    }
    .questionnaire{
        margin: 0;
        width: 90%;
    }
    .fio1{
        margin-bottom: 1%;
        width: 90%;
    }
    .questionnaire_right{
        width: 100%;
        margin-bottom: 20px;
    }
    .delivery_part_buttons{
        flex-direction: column;
    }
    .pmail_and_courierost{
        width: 100%;
        margin-bottom: 1%;
    }
    .pay_part_buttons{
        flex-direction: column;
    }
    .payment{
        width: 100%;
        margin-bottom: 1%;
    }
    .order_cart_price{
        display: none;
    }
    .order_cart_price_mobile{
        display: flex;
        flex-direction: column;
    }
    .order_cart_items{
        width: 95%;
        
    }
    .elements{
        margin-left: 10px;
    }
}