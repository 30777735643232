/*-- у страницы товара есть зависимость от main.css, данный файл создан для ненагромождения основного файла стилей --*/
.product_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.button1 {
    background-color: rgba(0, 0, 0, 0); /* Прозрачный зеленый цвет */
    border: 1px solid #130F26;
    color: #130F26; /* Цвет текста кнопки */
    
    font-family: Jura;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    width: 60px;
    height: 60px;
    transition: background-color 0.3s;
  }
  .button1-normal {
    background-color:rgba(0, 0, 0, 0);; /* Цвет фона кнопки в обычном состоянии */
    
  }
  .button-active {
    background-color: #0C4B57;
    color: white; /* Цвет текста кнопки в обычном состоянии */
  } 
.product{
    width: 90%;
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}
.left_side{
    width: 50%;
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    flex-direction: column;
}
.right_side{
    width: 50%;
    margin-top: 15px;
    justify-content: center;
}
.shoes_img{
    display: flex;
    flex-direction: row;
}
.shoes_img_right_arrow{
    display: flex;
    width: 12%;
    justify-content: center;
    align-items: center;
}

.shoes_img img{
    width: 100%;
}

.shoes_img_right_arrow button{
    border: none;
    background-color: rgba(0, 0, 0, 0,5);
    border-radius: 100%;
    height: 10%;
}
.min_shoes_img{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}
.min_shoes_img img {
    width: 19%;
    margin-right: 1%;
}
.min_shoes_item{

}
.min_shoes_item.active{
    border: #808080 2px solid;
}
.addtobasket_cont{
    display: flex;
    flex-direction: column;
}
.article_flex{
    display: flex;
    flex-direction: row;
}
.article{
    color: #808080;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.article_numbers{
    color: #000;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.nameshoes{
    color: #130F26;
    font-family: Jura;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.pp_price{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}
.actualprice{
    color: #130F26;
    font-family: Jura;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.notactualprice{
    margin-left: 20px;
    color: #808080;
    font-family: Jura;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: strikethrough;
}

.discount{
    margin-left: 15px;
    color: #F00;
    font-family: Jura;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.sizeshoes{
    max-width: 400px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}
.sizeshoes p{
    color: #130F26;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.size_type{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}
.size_type button{
    margin-right: 40px;
    color: #808080;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: rgba(0, 0, 0, 0);
}
.size_type button.activeTypeSize {
    color: black;
}

.sizeshoes_cont{
    width: 100%;
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 60px);
    gap: 10px;
}
.sizeshoes_item button{
    cursor: pointer;
}

.btn_addtobasket{
    margin-top: 30px;
}

.btn_addtobasket button{
    cursor: pointer;
    width: 216px;
    height: 45px;
    border: none;
    background-color: #0C4B57;
    color: white;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.descriptionshoes{
    margin-left: 5%;
    margin-top: 50px;
    width: 80%;
}
.desc_h2{
    margin-bottom: 20px;
    color: #130F26;
    font-family: Jura;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.desc_p{
    color: #130F26;
    font-family: Jura;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.detalization{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
}
.detalization_button{
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    border-top: #808080 2px solid;
    border-bottom: #808080 2px solid;
    border-left: none;
    border-right: none;
    padding-right: 4%;
    padding-left: 2%;
}
.detalization_button.active{
    border-bottom: none;
}
.detalization_button img{
    margin-left: auto;
}
.detalization_button p{
    font-size: 20px;
}
.detalization_text{
    background-color: ;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: #808080 2px solid;
}
.page_recomendation{
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 5%;
    margin-right: 5%;
    width: 80%;
}
.page_recomendation_name{
    color: #130F26;
    font-family: Jura;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.recomendation{
    justify-content: center;
    display: grid;
    margin-top: 30px;
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fill, 210px);
    gap: 5%;
}

@media screen and ((max-width: 1491px)){
    .nameshoes{
        font-size: 30px;
    }
}

@media screen and ((max-width: 1118px)){
    .nameshoes{
        font-size: 27px;
    }
}

@media screen and ((max-width: 1051px)){
    .nameshoes{
        font-size: 25px;
    }
}

@media screen and ((max-width: 931px)){
    .discount{
        font-size: 15px;
    }
    .notactualprice{
        font-size: 15px;
    }
    .actualprice{
        font-size: 20px;
        font-weight: bold;
    }
    .nameshoes{
        font-size: 20px;
        font-weight: bold;
    }
}

@media screen and ((max-width: 864px)){
    .sizeshoes_cont{
        grid-template-columns: repeat(auto-fill, 50px);
    }
    .button1{
        font-size: 16px;
        height: 50px;
        width: 50px;
    }
    
    .sizeshoes{
        margin-top: 0px;
    }
    .pp_price{
        margin-top: 0px;
    }
}

@media screen  and ((max-width: 767px)) {
    .sizeshoes_cont{
        grid-template-columns: repeat(auto-fill, 50px);
        gap: 5px;
    }
    .btn_addtobasket{
        width: 100%;
    }
    .btn_addtobasket button{
        width: 100%;
    }
    .button1{
        font-size: 16px;
        height: 50px;
        width: 50px;
    }
    .product{
        flex-direction: column;
        justify-content: center;
        max-width: 80%;
    }
    .left_side{
        width: 100%;
        margin: 0;
    }
    .right_side{
        width: 100%;
        margin-top: 15px;
        justify-content: center;
        
    }
    .shoes_img{
        display: flex;
    }
    .shoes_img img{
        width: 100%;
        justify-content: center;    
    }
    .min_shoes_img img {
        max-width: 100%;
        height: 19%;
        margin-right: 1%;
    }
    .descriptionshoes{
        max-width: 95%;
        margin: 0;
    }
    .desc{
        margin: 0;
        padding-top: 20px;
    }

    .recomendation {
        margin: 0;
        height: 350px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; /* Для плавной прокрутки на iOS */
    }
}