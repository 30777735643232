h1, h2, p, a{
    
    color: #130F26;
}
.faq_cont h1{
    margin-top: 100px;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 50px;
}

.question h2{
    font-weight: 600;
    font-size: 24px;
}

.faq_item{
    padding: 30px 20px;
    border-top: 1px solid #130F26;
    
}

.faq_item:last-child{
    border-bottom: 1px solid #130F26;
    margin-bottom: 100px;
}

.question{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question_img{
    transform: rotate(180deg);
}

@media screen and ((max-width: 768px)) {
    .faq_cont h1{
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: center;
    }

    .faq_item{
        padding: 10px 20px;
    }

    .question h2{
        font-size: 16px;
    }
}