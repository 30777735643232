.search_popup {
    display: flex;
    position: absolute;
    width: 100%;
    height: 0;
    z-index: 10;
    transition: height 1s ease, background-color 1s ease;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.search_popup.active {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh; 
    
}
.search_popup_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.search_input{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;    
    background-color: white;
    padding-bottom: 20px;
}

.search_input input{
    font-family: 'Jura', sans-serif;
    width: 50%;
    height: 30px;
    border: 1px solid grey;
    border-radius: 3px;
    padding-left: 0.375rem;
}

.search_input input:focus{
  color: #212529;
  background-color: #fff;
  border-color: #bdbdbd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);
}

.search_button{
    width: 10%;
    height: 34px;
    margin-left: 30px;
}
.search_button button{
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #0C4B57;
    color: white;
}
.search_result{
    background-color: white;
    display: flex;
    width: 100%;
    justify-content: center;
}
.search_result_content{
    height: 250px;
    display: flex;
    width: 90%;
    overflow-x: auto;
}