
.CategoryPopup{
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    width: 100%;
    z-index: 10;
    background-color: rgb(255, 255, 255);
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
}
.CategoryPopup.active{
    height: 300px;
    transform: translateY(0);
    padding-bottom: 30px;
}
.CategoryPopup_content{
    width: 90%;
    display: flex;
    justify-content: center;
    max-width: 600px;
}
.CategoryPopup h2{
    margin-bottom: 10px;
    font-size: 20px;
}
.CategoryPopup_gender{
    display: flex;
    flex-direction: column;
    width: 180px;
}
.CategoryPopup_gender p{
    margin-bottom: 5px;
    font-size: 15px;
}

.CategoryPopup_subbrands{
    width: 150px;
    display: flex;
    flex-direction: column;
}
.CategoryPopup_subbrands h2{
    margin-bottom: 10px;
}
.CategoryPopup_subbrands_list{
    height: 100%;
    display: flex;
    flex-direction: column;

}

.CategoryPopup_subbrands_list p{
    margin-bottom: 5px;
    font-size: 15px;
}