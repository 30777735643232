.modal{
    z-index: 9999;
    height: 100vh !important;
    width: 100vw !important;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active{
    opacity: 1;
    pointer-events: all;
}
.modal__content{
    padding: 20px;
    background-color: white;
    width: 60%;
    height: 60%;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    transform: scale(0.5);
    transition: 0.4s all;
}
.modal__content.active{
    transform: scale(1);   
}
.ecom-widget{
    width: 100%;
    height: 97%;
}

@media screen and ((max-width: 767px)) {
    .modal__content{
        width: 100%;
        height: 100%;
    }

}