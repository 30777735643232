@media screen and (min-width: 768px){
    .main_cont{
        display: flex;
        height: 90px;
        flex-direction: row;
        width: 100%;
    }
    
    .logo{
        display: flex;
        width: 15%;
        /* border: #130F26 solid 1px; */
    }

    .logo img{   
        margin-left: 20%;
        margin-top: 10.5%;
        width: 60%;
        height: 40%;
    }

    .main_navigation{
        display: flex;
        flex-direction: row;
        width: 70%;
        justify-content: center;
        align-items: center;
    }
    
    .main_navigation_chapter{
        padding-left: 30px;
        padding-right: 30px;
        color: var(--, #130F26);
        font-family: Jura;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main_navigation_chapter.active{
        background-color: #f6f6e3;
    }
    
    .auth{
        /* border: #130F26 solid 1px; */
        width: 15%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .auth button{
        margin-left: 3%;
        margin-right: 3%;
        padding: 0;
        background-color: rgba(0,0,0,0);
        border: none;
    }
    .cart_count1 {
        position: absolute;
        background-color: #ff0000; /* Цвет фона кружка */
        color: #fff; /* Цвет текста в кружке */
        border-radius: 50%; /* Делаем круглый кружок */
        width: 15px; /* Ширина кружка */
        height: 15px; /* Высота кружка */
        font-size: 12px; /* Размер шрифта текста в кружке */
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(0%, 120%); /* Центрируем кружок относительно иконки корзины */
      }

}