.contacts{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 50vh;
}
.refund li{
    list-style-type: disc;
    margin-left: 5%;
    font-family: 'Jura', sans-serif;
    color: #130F26;
}
