.mobile_menu {
    z-index: 10001;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: auto;
    transition: width 0.5s ease;
    visibility: hidden;
    opacity: 0;
}

.mobile_menu.active {
    visibility: visible;
    opacity: 1;
    width: 100vw;
}

.mobile_menu_content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 65%;
    background-color: white;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile_menu_content_header{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
}
.mobile_menu_content_header h2 {
    color: rgb(192, 192, 192);
    font-size: 22px;
}
.mobile_menu_content_header button{
    margin-left: auto;
    border: none;
    background-color: rgba(0, 0, 0, 0);
}

.mobile_menu.active .mobile_menu_content {
    transform: translateX(0);
}



.mobile_menu_content_button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
    font-size: 16px;
}
.mobile_menu_content_button p{
    font-size: 14px;
}

.mobile_menu_content_button img {
    width: 10px;
    margin-right: 10px;
    margin-top: 3px;
    margin-bottom: 20px;
}

.mobile_menu_content_button_brand,
.mobile_menu_content_button_category {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    height: 0;
    transition: max-height 0.5s ease;
    
}

.mobile_menu_content_button_brand.active,
.mobile_menu_content_button_category.active {
    height: 550px;
    transition: height 0.5s ease;
    overflow: auto;
    margin-bottom: 10px;
}

.mobile_menu_content_button_brand h3,
.mobile_menu_content_button_category h3 {
    color: rgb(114, 110, 110);
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 900;
    font-size: 16px;
}

.mobile_menu_content_button_brand p,
.mobile_menu_content_button_category p {
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 5px;
}

.mobile_menu_content_button_category h3 {
    color: rgb(192, 192, 192);
    margin-bottom: 10px;
    margin-top: 10px;
}

.mobile_menu_content_button_brand_a{
    color: rgb(114, 110, 110);
    margin-left: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 900;
    font-size: 20px;
}
