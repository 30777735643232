.main_items{
    margin-top: 7%;
    margin-bottom: 7%;
    margin-left: 9%;
    margin-right: 9%;
    justify-content: center;
}

.main_items:nth-last-child(1){
    margin-bottom: 30px;
}

.main_shoes{
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    color: #130F26;
    font-family: Jura;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.sale_cont {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sale_cont_item{
    margin-right: 20px;
    margin-left: 20px;
}

@media screen  and ((max-width: 900px)) {
    .sale_cont {
        list-style: none;
        overflow-x: auto;
        overflow-y: none;
        justify-content: flex-start;
        width: 100%;
    }
    .main_items{
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 0%;
    }
    .main_shoes{
        font-size: 20px;
        margin-left: 10px;
    }

}