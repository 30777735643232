.shoes_main{
	margin-top:20px;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	margin-bottom: 20px;
}

.shoes_main.mobile{
	flex-direction: column;
	align-items: center;
}

.filter_desktop{
	min-width: 150px;
	max-width: 200px;
	margin-right: 30px;
}

.shoes_products{
	width: 70%;
	max-width: 750px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 40vh;
}

.shoes_page_items{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}
.shoes_page_item{
	margin: 14px;
	min-height: 270px;
	max-height: 270px;
}
.filter_mobile{
    display: flex;
    width: 75%;
    justify-content: center;
}

.filter_mobile_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border: none;
    color: white;
    font-size: 14px;
    background-color: rgb(195, 201, 199);
    border-radius: 10px;
}
.filter_mobile_button img{
    width: 15px;
    margin-right: 10px;
}

@media screen  and ((max-width: 768px)) {

	.shoes_products{
		width: 100%;
		max-width: 490px;
	}
	.shoes_page_item{
		margin: 5px;
	}
	
}


