h3{
    font-family: 'Jura', sans-serif;
}

.table_current_order_top{
    display: flex;
    flex-direction: column;
    width: 85%;
}

.Summa_order{
    font-size: 20px;
    text-align: right;
    margin-right: 20px;
    margin-bottom: 40px;
    color: rgb(12, 201, 12);
}

.Summa_order span{
    font-size: 24px;
    text-decoration: underline;
    color: black;
}

.table_current_order_bottom_right{
}

.table_current_order_bottom_right h2{
    background-color: #f7f7f7;
    text-align: center;
    padding: 10px 0;
}

.table_current_full_info{
    margin: 20px 10px;
    border: 1px grey;
    border-style: dashed solid;
}

.tcfiw{
    margin-bottom: 10px;
}

.table_current_full_info h3{
    background-color: #f7f7f7;
    padding: 10px 0 10px 10px;
}

.table_current_full_info p{
    margin-top: 10px;
    margin-left: 30px;
}

.table_current_order_bottom_right_span_p{
    font-weight: bold;
    font-size: 18px;
}

.table_current_btn_сancellations{
    padding: 10px 10px;
    display: flex;
    justify-content: center;
}

.table_current_find_delivery{
    margin-left: 30px;
    padding: 20px 0 10px 0;
}

.table_current_find_delivery button{
    cursor: pointer;
    padding: 10px 20px;
    border: 0;
    border-radius: 5px;
    color:#f7f7f7;
    background-color: lightgreen;
}

.table_current_find_delivery.active_cdek button{
    background-color: lightgreen;
}
.table_current_find_delivery.active_boxberry button{
    background-color: #E34234;
}
.table_current_find_delivery.active_pochta button{
    background-color: blue;
}

.table_current_btn_сancellations button{
    cursor: pointer;
    border: 0;
    background-color: #E34234;
    padding: 10px 30px;
    color: #f7f7f7;
    border-radius: 5px;
    text-transform: uppercase;
}

@media screen  and ((max-width: 420px)){
    .Summa_order{
        font-size: 16px;
        margin-bottom: 15px;
    }

    .Summa_order span{
        font-size: 18px;
    }

    .table_current_full_info p {
        margin-left: 10px;
        font-size: 14px;
    }
    .table_current_full_info span{
        font-size: 14px;
    }
}