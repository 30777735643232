.payment1{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 50vh;
}

.payment_payments{
    max-width: 600px;
    text-align: center !important;
    margin: 0 auto;
}

.payment1 p{
    text-align: justify;
    
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.payment1_h1{
    text-align: center;
    padding: 10px 0;
}

.payment1 h2{
    padding: 10px 5px;
    background-color: #f7f7f7;
}

.payment1 h3{
    margin-left: 20px;
    padding: 10px 0;
}

.payment_payments{
    max-width: 600px;
    text-align: center !important;
    margin: 0 auto;
}

@media screen  and ((max-width: 767px)){
    .payment1{
        margin-left: 0;
        margin-right: 0;
        width: 95%;
        margin: 0 auto;
        padding-bottom: 20px;
    }
}