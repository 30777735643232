.offer_agreement{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
.offer_agreement_p{
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.offer_agreement_p p{
    margin-left: 5%;
    font-family: 'Jura', sans-serif;
    color: #130F26;
}

@media screen  and ((max-width: 767px)){
    .offer_agreement{
        margin-left: 0;
        margin-right: 0;
        width: 95%;
        margin: 0 auto;
        padding-bottom: 20px;
    }
}