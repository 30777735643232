.header {
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  /* Добавьте другие стили, какие вам нужны */
  z-index: 9998;
}

.outlet {
  padding-top: 90px;
  height: 80%;
}

.layout {

}
