footer{
    height: 20%;
    width: 100%;
    background: linear-gradient(to right, #288182, #0C4B57);
    align-items: center;
}
.mobile_footer{
    display: none;
}
.desktop_footer{
    height: 300px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}
.desktop_footer_line{
    margin-bottom:50px;
    height: 300px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}
.desktop_footer h3{
    margin-bottom: 20px;
}
.desktop_footer_3 h3{
    color: white;
}
.desktop_footer_1{
    color: white;
    width: 20%;
    margin-top: 40px;
}

.desktop_footer a:hover{
    text-decoration: underline;
    
}

.desktop_footer_2{
    width: 20%;
    margin-top: 40px;
    color: white;
}
.desktop_footer_3{
    width: 20%;
    margin-top: 40px;
    color: white;
}
.desktop_footer_3 img{
    width: 24px;
    margin-right: 5px;
}
.desktop_footer_4{
    width: 20%;
    margin-top: 40px;
    color: white;
    padding-left: 10%;
}
.desktop_footer_4 img{
    height: 24px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.desktop_footer_contact_content_phone{
    display: flex;
    margin-bottom: 5px;
}
.desktop_footer p{
    color: white;

}

@media screen  and ((max-width: 1240px)) {
    .desktop_footer p{
        font-size: 14px;
    }
}

@media screen  and ((max-width: 767px)) {
    .desktop_footer{
        display: none;
    }
    .mobile_footer p{
        color: #F9F9F9;
        font-family: Jura;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 18.2px */
        padding-left: 20px ;
        padding-top: 20px ;
    }
    .mobile_footer{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .mobile_footer_information{
        width: 100%;
        height: 64px;
        color: white;
        border-bottom: white 2px solid;
        display: flex;
        
    }
    .mobile_footer_contact_content p{
        color: #130F26;
    }
    .mobile_footer_information.active{
        background-color: #F9F9F9;
        color: black;
        border: none;
    }
    .mobile_footer_information.active p{
        color: black;
        
        
    }
    .mobile_footer_information button{
        width: 100%;
        display: flex;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        flex-direction: row;
        justify-content: flex-start;
    }
    .mobile_footer_information img{
        margin-top: 25px;
        margin-left: auto;
        margin-right: 20px;
    }

    .mobile_footer_information_content{
        background-color: #F9F9F9;
        height: 0;
        transition: height 0.5s ease; 
        overflow: hidden;
    }
    .mobile_footer_information_content.active{
        height: 250px;
    }
    .mobile_footer_information_content p{
        color:black;
    }


    .mobile_footer_contact{
        width: 100%;
        height: 64px;
        color: white;
        border-bottom: white 2px solid;
        display: flex;
        
    }
    .mobile_footer_contact.active{
        background-color: #F9F9F9;
        color: black;
        border: none;
        
    }
    .mobile_footer_contact.active p{
        color: black;
        
    }
    .mobile_footer_contact button{
        width: 100%;
        display: flex;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        flex-direction: row;
        justify-content: flex-start;
    }
    .mobile_footer_contact img{
        margin-top: 25px;
        margin-left: auto;
        margin-right: 20px;
    }

    .mobile_footer_contact_content{
        background-color: #F9F9F9;
        height: 0;
        transition: height 0.5s ease; /* Добавление плавности */
        overflow: hidden;
    }


    .mobile_footer_contact_content.active{
        height: 250px;

    }
    .mobile_footer_contact_content_phone{
        margin-left: 20px;
        display: flex;
    }
    .mobile_footer_contact_content_phone p{
        color: #130F26;
        font-family: Jura;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .mobile_footer_contact_content_phone img{
        width: 24px;
        height: 24px;
        margin-top: 15px;
    }

    .mobile_footer_contact_content_email{
        margin-left: 20px;
        display: flex;
    }
    .mobile_footer_contact_content_email p{
        color: #130F26;
        font-family: Jura;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .mobile_footer_contact_content_email img{
        width: 24px;
        height: 24px;
        margin-top: 15px;
    }
    .mobile_footer_contact_content_address{
        margin-left: 20px;
        display: flex;
    }
    .mobile_footer_contact_content_address p{
        color: #130F26;
        font-family: Jura;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-right: 20px;
    }
    .mobile_footer_contact_content_address img{
        width: 24px;
        height: 24px;
        margin-top: 15px;
    }

    .mobile_footer_copyright{
        width: 100%;
        height: 115px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mobile_footer_copyright_icons{
        display: flex;
        justify-content: center;
        width: 100%;
        
        margin-top: 20px;
    }
    .mobile_footer_copyright_icons img{
        height: 28px;
        margin-right: 20px;
    }
    .mobile_footer_copyright_text p{
        color: var(--, #F9F9F9);
        font-family: Jura;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        padding-left: 0;
    }
}